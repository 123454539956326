var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CampaignManager", {
    attrs: {
      type: "CAMPAIGN_MANAGER_STARTING",
      "func-get-opportunities": _vm.getOpportunityStarting,
      "show-date-picker": false,
      "start-date": _vm.today,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }