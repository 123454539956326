<template>
  <CampaignManager
    type="CAMPAIGN_MANAGER_STARTING"
    :func-get-opportunities="getOpportunityStarting"
    :show-date-picker="false"
    :start-date="today"
  ></CampaignManager>
</template>
<script>
import CampaignManager from '@/views/private/modules/thirdParty/campaignManager/CampaignManager.vue';
import { getOpportunityStarting } from '@/services/modules/ThirdParty/opportunity';
import { TODAY } from '@/utils/dateTime/today';

export default {
  name: 'CampaignManagerStarting',
  components: { CampaignManager },
  data() {
    return {
      today: TODAY,
    };
  },
  methods: {
    getOpportunityStarting,
  },
};
</script>
